import * as React from 'react';
import {Component, createContext} from 'react';
import { IWithSpidContext, withSpidContext } from './withSpidContext';
import { IClientContext } from './ClientContextProvider';
import { withClientContext } from './withClientContext';
import {IWithWPContext, withWPContext} from "./withWPContext";

export interface ISubscriptionsContext {
  state: ISubscriptionsContextState | null;
}

const SubscriptionsContext = createContext<ISubscriptionsContext>({
  state: {
    subscriptions: [],
  },
});
export const SubscriptionsContextConsumer = SubscriptionsContext.Consumer;

interface IWithSubscriptionsContext extends IWithSpidContext, IClientContext, IWithWPContext {

}
export interface ISubscription {
  bundleCode: string;
}
export interface ISubscriptionsContextState {
  subscriptions: ISubscription[];
}

class SubscriptionsContextProvider extends Component<IWithSubscriptionsContext, ISubscriptionsContextState> {

  constructor(props: IWithSubscriptionsContext, context: any) {
    super(props as any, context);
  }

  private init(props: IWithSubscriptionsContext): void {
    if (props.spidContext.state.sig) {
      fetch(`/kundeservice/tjenester2/${props.clientShortHandName}/1/customers/${props.spidContext.state.userId}/subscriptions`, {
        headers: {
          'x-score-spid-sig': props.spidContext.state.sig,
        }
      })
        .then(r => r.json())
        .then(r => this.setState({ subscriptions: r as any }))
        .catch(e => console.log('Error: ', e));
    }
  }

  componentWillMount() {
    if(this.props.maintenance && !this.props.maintenance.active) {
      this.init(this.props);
    }
  }

  componentWillReceiveProps(props: IWithSubscriptionsContext) {
    if (!this.props.spidContext.state.sig && props.spidContext.state.sig) {
      if(this.props.maintenance && !this.props.maintenance.active) {
        this.init(props);
      }
    }
  }

  render() {
    const {state} = this;
    const context = {
      state,
    };
    return <SubscriptionsContext.Provider value={context}>
      {this.props.children}
    </SubscriptionsContext.Provider>;
  }
}

export default withWPContext(withClientContext<any>(withSpidContext(SubscriptionsContextProvider)));
